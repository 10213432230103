import * as React from 'react';
import { debounce, keyBy } from 'lodash';
import * as lunr from 'lunr';
import { css } from '@emotion/core';
import { useStaticQuery, graphql } from 'gatsby';
import 'typeface-merienda-one';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/hero';
import Shareable from '../components/shareable';
import padding from '../util/padding';
import { breaks } from '../util/mq';

const ShareablesPage = () => {
  const data = useStaticQuery<{
    site: {
      siteMetadata: {
        description: string;
      };
    };
    hero: { childImageSharp: { fluid: { src: string; srcSet: string } } };
    images: {
      nodes: Array<{
        name: string;
        image: {
          id: string;
          fluid: {
            srcSet: string;
          };
          original: {
            src: string;
            width: number;
            height: number;
          };
        };
      }>;
    };
    text: {
      nodes: Array<{
        name: string;
        childPlainText: {
          content: string;
        };
      }>;
    };
  }>(graphql`
    query {
      site {
        siteMetadata {
          description
        }
      }
      hero: file(
        relativePath: { eq: "marvin-meyer-SYTO3xs06fU-unsplash.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 75) {
            src
            srcSet
          }
        }
      }
      text: allFile(
        filter: {
          extension: { eq: "txt" }
          absolutePath: { regex: "/shareables/" }
        }
      ) {
        nodes {
          name
          childPlainText {
            content
          }
        }
      }
      images: allFile(
        filter: {
          extension: { ne: "txt" }
          absolutePath: { regex: "/shareables/" }
        }
        sort: { fields: name, order: DESC }
      ) {
        nodes {
          name
          image: childImageSharp {
            id
            fluid(jpegProgressive: true, maxWidth: 500, quality: 100) {
              srcSet
              originalName
              originalImg
            }
            original {
              width
              height
              src
            }
          }
        }
      }
    }
  `);

  const images = data.images.nodes;
  const texts = data.text.nodes;

  const [searchText, setSearchText] = React.useState('');

  const imagesByName = React.useMemo(() => {
    return keyBy(images, 'name');
  }, [images]);

  const searchIndex = React.useMemo(() => {
    const textsByName = keyBy(texts, 'name');

    return lunr(function() {
      this.field('text');
      Object.entries(imagesByName).forEach(([name, data]) => {
        const text = textsByName[name];
        this.add({
          id: name,
          data,
          text: text ? text.childPlainText.content : undefined,
        });
      });
    });
  }, [imagesByName, texts]);

  const searchChange = React.useMemo(
    () =>
      debounce((value: string) => {
        setSearchText(value);
      }, 200),
    [],
  );

  const searchResults = React.useMemo(() => {
    return searchIndex.search(searchText);
  }, [searchIndex, searchText]);

  return (
    <Layout withHero>
      <SEO
        title="Shareables"
        description={data.site.siteMetadata.description}
      />
      <Hero
        {...data.hero.childImageSharp.fluid}
        unsplashUser="marvelous"
        author="Marvin Meyer"
        title="Shareables"
        subTitle="(Mimages)"
        darken="0.25"
        position="-700px"
        css={css`
          margin-bottom: 5em;
        `}
      />
      <div
        css={css`
          ${padding}
          margin-bottom: 5em;
        `}
      >
        <input
          type="text"
          placeholder="Search by keyword or book title..."
          onChange={e => searchChange(e.currentTarget.value)}
          css={css`
            width: 100%;
            padding: 0.5em;
            outline-color: #9a2314;
          `}
        />
      </div>
      <div
        css={css`
          ${padding}
          display: grid;
          justify-items: center;
          grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
          @media (max-width: ${breaks.small}) {
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          }
        `}
      >
        {searchText
          ? searchResults.map(({ ref }) => {
              const { image } = imagesByName[ref];
              return <Shareable key={image.id} image={image} name={ref} />;
            })
          : images.map(({ image, name }) => {
              return <Shareable key={image.id} image={image} name={name} />;
            })}
      </div>
    </Layout>
  );
};

export default ShareablesPage;
