import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { css } from '@emotion/core';
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  PinterestShareButton,
  PinterestIcon,
  RedditShareButton,
  RedditIcon,
} from 'react-share';
import { easeOutExpo } from '../util/easings';
import { breaks } from '../util/mq';
import { useStaticQuery, graphql } from 'gatsby';

export type Props = {
  name: string;
  image: {
    fluid: {
      srcSet: string;
    };
    original: {
      src: string;
      width: number;
      height: number;
    };
  };
};

const makeShareProps = (url: string, delayMs: number) => ({
  url,
  css: css`
    margin-left: 8px;
    transform: translate3d(0, 100px, 0);
    transition-delay: ${delayMs}ms;
  `,
});

const Shareable = ({ image: { fluid, original }, name }: Props) => {
  const data = useStaticQuery<{
    site: { siteMetadata: { siteUrl: string } };
  }>(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const url = `${data.site.siteMetadata.siteUrl}/shareables/${name}`;

  const { css: linkCss } = makeShareProps(url, 200);

  return (
    <div
      css={css`
        position: relative;
        width: 500px;
        height: 500px;
        @media (max-width: ${breaks.small}) {
          width: 300px;
          height: 300px;
        }
        margin-bottom: 50px;
        overflow: hidden;
        &:hover,
        &:focus,
        &:focus-within {
          outline: none;
          .share-bar {
            transform: translate3d(0, 0, 0);
          }
          .share-bar-button {
            transition-duration: 400ms;
            transition-timing-function: ${easeOutExpo};
            transform: translate3d(0, 0, 0);
          }
        }
      `}
      role="button"
      tabIndex={0}
    >
      <img srcSet={fluid.srcSet} src={original.src} alt={name} />
      <div
        className="share-bar"
        css={css`
          display: flex;
          width: 100%;
          height: 50px;
          position: absolute;
          left: 0px;
          bottom: 0px;
          background: rgba(0, 0, 0, 0.75);
          color: white;
          transform: translate3d(0, 50px, 0);
          transition: transform 400ms ${easeOutExpo};
        `}
      >
        {/*
          // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
        <FacebookShareButton
          className="share-bar-button"
          {...makeShareProps(url, 40)}
        >
          <FacebookIcon size={24} />
        </FacebookShareButton>
        {/*
          // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
        <TwitterShareButton
          className="share-bar-button"
          {...makeShareProps(url, 80)}
        >
          <TwitterIcon size={24} />
        </TwitterShareButton>
        {/*
          // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
        <PinterestShareButton
          className="share-bar-button"
          {...makeShareProps(url, 120)}
          media={`${data.site.siteMetadata.siteUrl}${original.src}`}
        >
          <PinterestIcon size={24} />
        </PinterestShareButton>
        {/*
          // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
        <RedditShareButton
          className="share-bar-button"
          {...makeShareProps(url, 160)}
        >
          <RedditIcon size={24} />
        </RedditShareButton>
        <a
          href={`/shareables/${name}`}
          className="share-bar-button"
          target="_blank"
          css={css`
            color: #fff;
            ${linkCss};
            position: relative;
            top: 12px;
          `}
        >
          <FontAwesomeIcon icon={faLink} />
        </a>
      </div>
    </div>
  );
};

export default Shareable;
